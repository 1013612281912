import "./investor.scss";
import { useState, useEffect } from "react";
import useToken from '../../Hooks/useToken';
import { useAlert } from "react-alert";
import axios from "axios";


import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";

import deleteBtn from "../../assets/images/delete.svg";
import editBtn from "../../assets/images/edit.svg";

function Investors({ lang, setLang }) {
    const alert = useAlert();
    const [data, setData] = useState();
    const [token, setToken] = useToken()
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [found, setFound] = useState()
    const [id, setId] = useState();

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/partner')
            .then(res => res.json())
            .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
            .catch((e) => console.log(e))
    }, [lang])

    const checkboxChange = (e) => {
        const id = JSON.parse(e.target.dataset.id);
        const status = e.target.checked

        fetch(process.env.REACT_APP_API_URL + '/editPartner/' + lang, {
            method: "PUT",
            body: JSON.stringify({
                partner_id: id,
                partner_status: status,

            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }

    const HandleDelete = (e) => {
        const id = JSON.parse(e.target.dataset.id);

        fetch(process.env.REACT_APP_API_URL + '/partner/' + lang, {
            method: "Delete",
            body: JSON.stringify({
                partner_id: id
            }),
            headers: { token: token, "Content-Type": "application/json", },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }

    const HandleEdit = (evt) => {
        evt.preventDefault();
        const formData = new FormData();
        const { photo, link, title, status } = evt.target.elements

        formData.append("partner_id", id);
        formData.append("photo", photo.files[0]);
        formData.append("partner_name", title.value);
        formData.append("partner_link", link.value);
        formData.append("partner_status", status.value);

        axios.put(process.env.REACT_APP_API_URL + "/partner/" + lang, formData, {
            headers: {
                'Content-Type': 'form-data',
                'Accept': 'application/json',
                "type": "formData",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                token: token
            }
        })
            .then((data) => {
                if (data) {
                    if (data.data.status === 200) {
                        alert.success(data.data.message);
                    }
                    if (data.data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.data.message);
                        console.log(data);
                    }
                }
            });

        setEdit(false)
    }

    return (
        <>
            <Header />
            <main>
                <SearchInput lang={lang} input={false} setLang={setLang} />
                <section className="">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nomi</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.partner_name}</td>
                                            <td>
                                                <div className="customers_checkbox_wrapper">
                                                    <label className="checkbox-container customers_checkbox-container">
                                                        <input
                                                            defaultChecked={e.partner_status}
                                                            required
                                                            className="customer_input"
                                                            type="checkbox"
                                                            data-id={e.partner_id}
                                                            onChange={checkboxChange}

                                                        />
                                                        <span className="checkmark customers_checkmark">
                                                            <div></div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <button>
                                                    <img
                                                        src={editBtn}
                                                        alt="edit"
                                                        onClick={() => {
                                                            setId(e.partner_id);
                                                            setEdit(!edit)
                                                            setFound({
                                                                name: e.partner_name,
                                                                link: e.partner_link,
                                                                status: e.video_status,
                                                            })
                                                        }}
                                                    />
                                                </button>
                                                <button>
                                                    <img
                                                        src={deleteBtn}
                                                        alt="deleteBtn"
                                                        data-id={e.partner_id}
                                                        onClick={HandleDelete}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="add__btn-box">
                        <button className="add__btn" onClick={() => setModal(true)}>Qo`shish</button>
                    </div>

                    <div className={modal ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={
                                (evt) => {
                                    evt.preventDefault();
                                    const formData = new FormData();

                                    const { photo, title, status, link } = evt.target.elements

                                    formData.append("photo", photo.files[0]);
                                    formData.append("partner_name", title.value);
                                    formData.append("partner_link", link.value);
                                    formData.append("partner_status", status.checked);

                                    console.log()

                                    axios.post(process.env.REACT_APP_API_URL + "/partner/" + lang, formData, {
                                        headers: {
                                            'Content-Type': 'form-data',
                                            'Accept': 'application/json',
                                            "type": "formData",
                                            "Access-Control-Allow-Origin": "*",
                                            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                                            token: token
                                        }
                                    })
                                        .then((data) => {
                                            if (data) {
                                                if (data.data.status === 200) {
                                                    alert.success(data.data.message);
                                                }
                                                if (data.data.status === 401) {
                                                    setToken(false)
                                                }
                                                else {
                                                    alert.success(data.data.message);
                                                    console.log(data);
                                                }
                                            }
                                        });
                                    setModal(false)
                                }
                            }>
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Image"
                                            required
                                        />
                                    </label>
                                </div>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="title"
                                    required
                                    placeholder="Partner title"
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="link"
                                    placeholder="Partner link"
                                    required
                                />

                                <input
                                    className=""
                                    type="checkbox"
                                    name="status"
                                />

                                <button className="btn">Qo`shish</button>
                            </form>
                        </div>
                    </div>

                    <div className={edit ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={HandleEdit} >
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Image"
                                        />
                                    </label>
                                </div>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="title"
                                    placeholder="Partner title"
                                    defaultValue={found?.name || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="link"
                                    placeholder="Partner link"
                                    defaultValue={found?.link || ''}
                                    required
                                />

                                <input
                                    className=""
                                    type="checkbox"
                                    name="status"
                                    defaultChecked={found?.status}
                                />

                                <button className="btn">O`zgartirish</button>
                            </form>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
}

export default Investors;
