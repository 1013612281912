import React from "react";
import "./App.scss";
import { Routes, Route } from "react-router-dom";

import AddNews from "./components/addNews/addNews.jsx";
import Home from "./components/home/home";
import Users from "./components/users/users";
import Investors from "./components/investor/investor";
import Video from "./components/video/video";
import Activity from "./components/activity/activity";
import Appeals from "./components/appeals/appeals";
import Certificate from "./components/certificate/certificate";
import Management from "./components/management/management";
import Social from "./components/social/social";
import About from "./components/about/about";
import Gallery from "./components/gallery/gallery";

function AuthenticatedApp() {
  const [lang, setLang] = React.useState(JSON.parse(window.localStorage.getItem('lang')) || 'uz');

  return (
    <>
      <Routes>
        <Route path="/" element={<Home lang={lang} setLang={setLang} />} />
        <Route path="/addNew" element={<AddNews lang={lang} setLang={setLang} />} />
        <Route path="/users" element={<Users lang={lang} setLang={setLang} />} />
        <Route path="/investor" element={<Investors lang={lang} setLang={setLang} />} />
        <Route path="/video" element={<Video lang={lang} setLang={setLang} />} />
        <Route path="/activity" element={<Activity lang={lang} setLang={setLang} />} />
        <Route path="/appeals" element={<Appeals lang={lang} setLang={setLang} />} />
        <Route path="/certificate" element={<Certificate lang={lang} setLang={setLang} />} />
        <Route path="/management" element={<Management lang={lang} setLang={setLang} />} />
        <Route path="/social" element={<Social lang={lang} setLang={setLang} />} />
        <Route path="/about" element={<About lang={lang} setLang={setLang} />} />
        <Route path="/gallery" element={<Gallery lang={lang} setLang={setLang} />} />
      </Routes>
    </>
  );
}

export default AuthenticatedApp;
