import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";
import { useState, useEffect } from "react";
import useToken from '../../Hooks/useToken';
import { useAlert } from 'react-alert';


import deleteBtn from "../../assets/images/delete.svg";

function Appeals({ setLang, lang }) {
    const [token, setToken] = useToken()
    const alert = useAlert();
    const [data, setData] = useState()
    const [found, setFound] = useState()
    const [show, setShow] = useState(false)
    const [search, setSearch] = useState('')

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/appeals/' + search)
            .then(res => res.json())
            .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
            .catch((e) => console.log(e))
    }, [lang, search])


    const checkboxChange = (e) => {
        const id = JSON.parse(e.target.dataset.id);
        const status = e.target.checked

        fetch(process.env.REACT_APP_API_URL + '/appeals/' + lang, {
            method: "PUT",
            body: JSON.stringify({
                applicant_id: id,
                applicant_status: status
            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });

    }


    const HandleDelete = (e) => {
        const id = JSON.parse(e.target.dataset.id);

        fetch(process.env.REACT_APP_API_URL + '/appeals/' + lang, {
            method: "Delete",
            body: JSON.stringify({
                applicant_id: id,
            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                        console.log(data);
                    } else {
                        alert.error(data.message);
                        console.log(data);
                    }
                }
            });
    }

    return (
        <>
            <Header />
            <main>
                <SearchInput lang={lang} setSearch={setSearch} input={true} setLang={setLang} />
                <section className="appeals">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Ism</th>
                                    <th>Telefon</th>
                                    <th>Sinf</th>
                                    <th>Xabar</th>
                                    <th>Status</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.map((e, i) => (
                                    <tr key={i}>
                                        <td>{e.applicant_name}</td>
                                        <td>{e.applicant_phone}</td>
                                        <td>{e.applicant_class}</td>
                                        <td>{e.applicant_content?.split(' ').slice(0, 10).join(' ') + '...'}</td>
                                        <td>
                                            <div className="activity_checkbox_wrapper">
                                                <label className="checkbox-container activity_checkbox-container">
                                                    <input
                                                        defaultChecked={e.applicant_status}
                                                        className="activity_input"
                                                        type="checkbox"
                                                        data-id={e.applicant_id}
                                                        onChange={checkboxChange}
                                                    />
                                                    <span className="checkmark activity_checkmark">
                                                        <div></div>
                                                    </span>
                                                </label>
                                            </div>
                                        </td>
                                        <td>
                                            <button className='show' onClick={() => {
                                                setFound({
                                                    content: e.applicant_content
                                                })
                                                setShow(true)
                                            }}>
                                                ko'proq
                                            </button>
                                        </td>
                                        <td>
                                            <button onClick={HandleDelete}>
                                                <img
                                                    src={deleteBtn}
                                                    alt="deleteBtn"
                                                    data-id={e.applicant_id}
                                                />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className={show ? "modal" : "modal--close"}>
                        <div className='modal__item'>

                            <p>{found?.content}</p>
                            <button className='btn' onClick={() => setShow(false)}>
                                Qaytish
                            </button>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}

export default Appeals