import { useState, useEffect } from "react";
import useToken from "../../Hooks/useToken";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";

import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";


import deleteBtn from "../../assets/images/delete.svg";
import editBtn from "../../assets/images/edit.svg";

function Video({ lang, setLang }) {
    const [token] = useToken()
    const alert = useAlert();
    const navigate = useNavigate()
    const [data, setData] = useState();
    const [id, setId] = useState();
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [found, setFound] = useState()
    const [search, setSearch] = useState('')

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/video/" + search)
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru)
                }
            });
    }, [lang, search]);

    const HandleSubmit = (evt) => {
        evt.preventDefault();
        const { url, title, status } = evt.target.elements

        fetch(process.env.REACT_APP_API_URL + "/video/" + lang, {
            method: "post",
            body: JSON.stringify({
                video_title: title.value.trim(),
                video_url: url.value.trim(),
                video_status: status.checked
            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                        navigate('/video');
                        setModal(false)
                        console.log(data);
                    } else {
                        alert.error(data.message);
                        console.log(data);
                    }
                }
            });
    }

    const HandleUpdate = (evt) => {
        evt.preventDefault();
        const { url, title, status } = evt.target.elements

        fetch(process.env.REACT_APP_API_URL + "/video/" + lang, {
            method: "PUT",
            body: JSON.stringify({
                video_id: id,
                video_title: title.value.trim(),
                video_url: url.value.trim(),
                video_status: status.checked
            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                        navigate('/video');
                        setEdit(false)
                        console.log(data);
                    } else {
                        alert.error(data.message);
                        console.log(data);
                    }
                }
            });
    }

    const checkboxChange = (e) => {
        const id = JSON.parse(e.target.dataset.id);
        const status = e.target.checked

        fetch(process.env.REACT_APP_API_URL + '/editVideo/' + lang, {
            method: "PUT",
            body: JSON.stringify({
                video_id: id,
                video_status: status,

            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                        console.log(data);
                    } else {
                        alert.error(data.message);
                        console.log(data);
                    }
                }
            });
    }

    const HandleDelete = (e) => {
        const id = JSON.parse(e.target.dataset.id);

        fetch(process.env.REACT_APP_API_URL + '/video/' + lang, {
            method: "Delete",
            body: JSON.stringify({
                video_id: id
            }),
            headers: { token: token, "Content-Type": "application/json", },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                        console.log(data);
                    } else {
                        alert.error(data.message);
                        console.log(data);
                    }
                }
            });
    }


    return (
        <>
            <Header />
            <main>
                <SearchInput setSearch={setSearch} input={true} lang={lang} setLang={setLang} />
                <section>
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>nomi</th>
                                    <th>link</th>
                                    <th>status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.video_title}</td>
                                            <td><a href={e.video_url}>{e.video_url}</a></td>
                                            <td>
                                                <div className="customers_checkbox_wrapper">
                                                    <label className="checkbox-container customers_checkbox-container">
                                                        <input
                                                            defaultChecked={e.video_status}
                                                            required
                                                            className="customer_input"
                                                            type="checkbox"
                                                            data-id={e.video_id}
                                                            onChange={checkboxChange}

                                                        />
                                                        <span className="checkmark customers_checkmark">
                                                            <div></div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <button>
                                                    <img
                                                        src={editBtn}
                                                        alt="edit"
                                                        onClick={() => {
                                                            setId(e.video_id);
                                                            setEdit(!edit)
                                                            setFound({
                                                                title: e.video_title,
                                                                url: e.video_url,
                                                                status: e.video_status,
                                                            })
                                                        }}
                                                    />
                                                </button>
                                                <button>
                                                    <img
                                                        src={deleteBtn}
                                                        alt="deleteBtn"
                                                        data-id={e.video_id}
                                                        onClick={HandleDelete}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="add__btn-box">
                        <button className="add__btn" onClick={() => setModal(true)}>Qo`shish</button>
                    </div>


                    <div className={modal ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={HandleSubmit}>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="url"
                                    placeholder="Video url"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="title"
                                    placeholder="Video title"
                                    required
                                />

                                <input
                                    className=""
                                    type="checkbox"
                                    name="status"
                                />

                                <button className="btn">Qo`shish</button>
                            </form>
                        </div>
                    </div>

                    <div className={edit ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={HandleUpdate}>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="url"
                                    placeholder="Video url"
                                    defaultValue={found?.url || ""}
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="title"
                                    placeholder="Video title"
                                    defaultValue={found?.title || ""}
                                />

                                <input
                                    className=""
                                    type="checkbox"
                                    name="status"
                                    defaultChecked={found?.status}
                                />

                                <button className="btn">O`zgartirish</button>
                            </form>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}

export default Video