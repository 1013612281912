import React from 'react';
import Login from './components/login/login';

function UnauthenticatedApp() {
	return (
		<>
			<Login />
		</>
	);
}

export default UnauthenticatedApp;
