import { useState, useEffect } from "react";
import axios from 'axios'
import { useAlert } from 'react-alert';
import useToken from '../../Hooks/useToken';

import deleteBtn from "../../assets/images/delete.svg";
import editBtn from "../../assets/images/edit.svg";


import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";

function Management({ lang, setLang }) {
    const [found, setFound] = useState()
    const [edit, setEdit] = useState(false)
    const [id, setId] = useState();
    const alert = useAlert();
    const [token, setToken] = useToken()
    const [data, setData] = useState()
    const [modal, setModal] = useState(false)



    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/management')
            .then(res => res.json())
            .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
            .catch((e) => console.log(e))
    }, [lang])


    const HandleSubmit = (evt) => {
        evt.preventDefault();
        const formData = new FormData();
        const { photo, name, role, winning, degree, info, telegram, instagram, facebook, phone, email, reception, status } = evt.target.elements


        formData.append("photo", photo.files[0]);
        formData.append("management_name", name.value);
        formData.append("management_role", role.value);
        formData.append("management_winning", winning.value);
        formData.append("management_academic_degree", degree.value);
        formData.append("management_full_info", info.value);
        formData.append("management_telegram_link", telegram.value);
        formData.append("management_instagram_link", instagram.value);
        formData.append("management_facebook_link", facebook.value);
        formData.append("management_phone", phone.value);
        formData.append("management_email", email.value);
        formData.append("management_reception_time", reception.value);
        formData.append("management_status", status.checked);


        axios.post(process.env.REACT_APP_API_URL + "/management/" + lang, formData, {
            headers: {
                'Content-Type': 'form-data',
                'Accept': 'application/json',
                "type": "formData",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                token: token
            }
        })
            .then((data) => {
                if (data) {
                    if (data.data.status === 200) {
                        alert.success(data.data.message);

                    }
                    if (data.data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.data.message);
                        console.log(data);
                    }
                }
            });

        setModal(false)
    }

    const HandleEdit = (evt) => {
        evt.preventDefault();
        const formData = new FormData();

        const { photo, name, role, winning, degree, info, telegram, instagram, facebook, phone, email, reception, status } = evt.target.elements

        formData.append("management_id", id);
        formData.append("photo", photo.files[0]);
        formData.append("management_name", name.value);
        formData.append("management_role", role.value);
        formData.append("management_winning", winning.value);
        formData.append("management_academic_degree", degree.value);
        formData.append("management_full_info", info.value);
        formData.append("management_telegram_link", telegram.value);
        formData.append("management_instagram_link", instagram.value);
        formData.append("management_facebook_link", facebook.value);
        formData.append("management_phone", phone.value);
        formData.append("management_email", email.value);
        formData.append("management_reception_time", reception.value);
        formData.append("management_status", status.checked);

        axios.put(process.env.REACT_APP_API_URL + "/management/" + lang, formData, {
            headers: {
                'Content-Type': 'form-data',
                'Accept': 'application/json',
                "type": "formData",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                token: token
            }
        })
            .then((data) => {
                if (data) {
                    if (data.data.status === 200) {
                        alert.success(data.data.message);
                        console.log(data);
                    } else {
                        alert.error(data.data.message);
                        console.log(data);
                    }
                }
            });

        setModal(false)
    }

    const HandleDelete = (e) => {
        const id = JSON.parse(e.target.dataset.id);

        fetch(process.env.REACT_APP_API_URL + '/management/' + lang, {
            method: "Delete",
            body: JSON.stringify({
                management_id: id
            }),
            headers: { token: token, "Content-Type": "application/json", },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }

    const checkboxChange = (e) => {
        const id = JSON.parse(e.target.dataset.id);
        const status = e.target.checked

        fetch(process.env.REACT_APP_API_URL + '/editManagement/' + lang, {
            method: "PUT",
            body: JSON.stringify({
                management_id: id,
                management_status: status,

            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }

    return (
        <>
            <Header />
            <main>
                <SearchInput lang={lang} input={false} setLang={setLang} />
                <section className="management">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nomi</th>
                                    <th>role</th>
                                    <th>telefon</th>
                                    <th>status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.management_name}</td>
                                            <td>{e.management_role}</td>
                                            <td>{e.management_phone}</td>
                                            <td>
                                                <div className="customers_checkbox_wrapper">
                                                    <label className="checkbox-container customers_checkbox-container">
                                                        <input
                                                            defaultChecked={e.management_status}
                                                            required
                                                            className="customer_input"
                                                            type="checkbox"
                                                            data-id={e.management_id}
                                                            onChange={checkboxChange}

                                                        />
                                                        <span className="checkmark customers_checkmark">
                                                            <div></div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <button>
                                                    <img
                                                        src={editBtn}
                                                        alt="edit"
                                                        onClick={() => {
                                                            setId(e.management_id);
                                                            setEdit(!edit)
                                                            setFound({
                                                                name: e.management_name,
                                                                role: e.management_role,
                                                                winning: e.management_winning,
                                                                degree: e.management_academic_degree,
                                                                info: e.management_full_info,
                                                                telegram: e.management_telegram_link,
                                                                instagram: e.management_instagram_link,
                                                                facebook: e.management_facebook_link,
                                                                phone: e.management_phone,
                                                                email: e.management_email,
                                                                reception: e.management_reception_time,
                                                                status: e.management_status,
                                                            })
                                                        }}
                                                    />
                                                </button>
                                                <button>
                                                    <img
                                                        src={deleteBtn}
                                                        alt="deleteBtn"
                                                        data-id={e.management_id}
                                                        onClick={HandleDelete}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="add__btn-box">
                        <button className="add__btn" onClick={() => setModal(true)}>Qo`shish</button>
                    </div>


                    <div className={modal ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={HandleSubmit}>
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Image"
                                        />
                                    </label>
                                </div>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="name"
                                    placeholder="Ismi"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="role"
                                    placeholder="Lavozimi"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="winning"
                                    placeholder="Yutuqlari"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="degree"
                                    placeholder="Ilmiy darajasi"
                                    required
                                />

                                <textarea
                                    cols="50"
                                    rows="10"
                                    className="input-info"
                                    type="text"
                                    name="info"
                                    placeholder="Info"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="telegram"
                                    placeholder="Telegram link"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="instagram"
                                    placeholder="Instagram link"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="facebook"
                                    placeholder="Facebook link"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="phone"
                                    placeholder="Phone number"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="email"
                                    placeholder="Email address"
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="reception"
                                    placeholder="Qabul vaqti"
                                    required
                                />

                                <label>
                                    status
                                    <input
                                        className="input-checkbox"
                                        type="checkbox"
                                        name="status"
                                    />
                                </label>

                                <button className="btn">Qo`shish</button>
                            </form>
                        </div>
                    </div>

                    <div className={edit ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={HandleEdit}>
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Imge"
                                        />
                                    </label>
                                </div>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="name"
                                    placeholder="Ismi"
                                    defaultValue={found?.name || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="role"
                                    placeholder="Lavozimi"
                                    defaultValue={found?.role || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="winning"
                                    placeholder="Yutuqlari"
                                    defaultValue={found?.winning || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="degree"
                                    placeholder="Ilmiy darajasi"
                                    defaultValue={found?.degree || ''}
                                    required
                                />

                                <textarea
                                    cols="50"
                                    rows="10"
                                    className="input-info"
                                    type="text"
                                    name="info"
                                    placeholder="Info"
                                    defaultValue={found?.info || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="telegram"
                                    placeholder="Telegram link"
                                    defaultValue={found?.telegram || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="instagram"
                                    placeholder="Instagram link"
                                    defaultValue={found?.instagram || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="facebook"
                                    placeholder="Facebook link"
                                    defaultValue={found?.facebook || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="phone"
                                    placeholder="Phone number"
                                    defaultValue={found?.phone || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="email"
                                    placeholder="Email address"
                                    defaultValue={found?.email || ''}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="reception"
                                    placeholder="Qabul vaqti"
                                    defaultValue={found?.reception || ''}
                                    required
                                />

                                <label>
                                    status
                                    <input
                                        className="input-checkbox"
                                        type="checkbox"
                                        name="status"
                                        defaultChecked={found?.status}
                                    />
                                </label>

                                <button className="btn">O`zgartirish</button>
                            </form>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}

export default Management