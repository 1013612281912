import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useToken from "../../Hooks/useToken";
import axios from "axios";
import { useAlert } from "react-alert";

import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";

import deleteBtn from "../../assets/images/delete.svg";
import editBtn from "../../assets/images/edit.svg";

function Home({ lang, setLang }) {
  const navigate = useNavigate()
  const alert = useAlert()
  const [data, setData] = useState()
  const [edit, setEdit] = useState(false)
  const [found, setFound] = useState()
  const [id, setId] = useState();
  const [token, setToken] = useToken()
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')


  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/post/' + search, {
      method: "GET"
    })
      .then(res => res.json())
      .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
      .catch((e) => console.log(e))
  }, [lang, search])

  const checkboxChange = (e) => {
    const id = JSON.parse(e.target.dataset.id);
    const status = e.target.checked

    fetch(process.env.REACT_APP_API_URL + '/editPost/' + lang, {
      method: "PUT",
      body: JSON.stringify({
        post_id: id,
        post_status: status,

      }),
      headers: {
        "Content-Type": "application/json",
        token: token
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          if (data.status === 200) {
            alert.success(data.message);
          }
          if (data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.message);
            console.log(data);
          }
        }
      });
  }

  const HandleDelete = (e) => {
    const id = JSON.parse(e.target.dataset.id);

    fetch(process.env.REACT_APP_API_URL + '/post/' + lang, {
      method: "Delete",
      body: JSON.stringify({
        post_id: id
      }),
      headers: { token: token, "Content-Type": "application/json", },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          if (data.status === 200) {
            alert.success(data.message);
            console.log(data);

          }
          if (data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.message);
            console.log(data);
          }
        }
      });
  }

  const HandleEdit = (evt) => {
    evt.preventDefault();
    const { photos, type, name, title, disc, who, video_one, video_two, video_three, status } = evt.target.elements

    const formData = new FormData();

    formData.append("post_id", id);

    for (let i = 0; i < photos.files.length; i++) {
      formData.append("photos", photos.files[i]);
    }
    formData.append("post_name", name.value);
    formData.append("post_title", title.value);
    formData.append("post_discription", disc.value);
    formData.append("post_type", type.value);
    formData.append("post_created_by", who.value);
    formData.append("post_video_one", video_one.value);
    formData.append("post_video_two", video_two.value);
    formData.append("post_video_three", video_three.value);
    formData.append("post_status", status.checked);

    axios.put(process.env.REACT_APP_API_URL + "/post/" + lang, formData, {
      headers: {
        "type": "formData",
        "Content-Type": "form-data",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        token: token
      }
    })
      .then((data) => {
        if (data) {
          if (data.data.status === 200) {
            alert.success(data.data.message);

          }
          if (data.data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.data.message);
            console.log(data);
          }
        }
      });
  }


  return (
    <>
      <Header />
      <main>
        <SearchInput setLang={setLang} input={true} setSearch={setSearch} lang={lang} />
        <section className="">
          <div className="container">
            <table>
              <thead>
                <tr>
                  <th>Nomi</th>
                  <th>title</th>
                  <th>desc</th>
                  <th>type</th>
                  <th>kim</th>
                  <th>status</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.map((e, i) => (
                    <tr key={i}>
                      <td>{e.post_name}</td>
                      <td>{e.post_title.split(' ').slice(0, 2).join(' ') + '...'}</td>
                      <td>{e.post_discription.split(' ').slice(0, 2).join(' ') + '...'}</td>
                      <td>{e.post_type}</td>
                      <td>{e.post_created_by}</td>
                      <td>
                        <div className="customers_checkbox_wrapper">
                          <label className="checkbox-container customers_checkbox-container">
                            <input
                              defaultChecked={e.post_status}
                              required
                              className="customer_input"
                              type="checkbox"
                              data-id={e.post_id}
                              onChange={checkboxChange}

                            />
                            <span className="checkmark customers_checkmark">
                              <div></div>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <button className='show' onClick={() => {
                          setFound(
                            {
                              title: e.post_title,
                              desc: e.post_discription
                            }
                          )
                          setShow(true)
                        }}>
                          ko'proq
                        </button>
                      </td>
                      <td>
                        <button>
                          <img
                            src={editBtn}
                            alt="edit"
                            onClick={() => {
                              setId(e.post_id);
                              setEdit(!edit)
                              setFound({
                                title: e.post_title,
                                desc: e.post_discription,
                                created_by: e.post_created_by,
                                name: e.post_name,
                                status: e.post_status,
                                type: e.post_type,
                                video_one: e.post_video_one,
                                video_two: e.post_video_two,
                                video_three: e.post_video_three,
                                add_link_url: e.add_link_url,
                                add_link_title: e.add_link_title
                              })
                            }}
                          />
                        </button>
                        <button>
                          <img
                            src={deleteBtn}
                            alt="deleteBtn"
                            data-id={e.post_id}
                            onClick={HandleDelete}
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>


          <div className="add__btn-box">
            <button className="add__btn" onClick={() => navigate('/addNew')}>Qo`shish</button>
          </div>

          <div className={edit ? "modal" : "modal--close"}>
            <div className="modal__item">
              <form className="l" onSubmit={HandleEdit}>
                <div className="form__box">

                  <select className="select" name="type" defaultValue={found?.type} required>
                    <option value="0" disabled>Turini tanlang</option>
                    <option value="Yangliklar">Yangliklar</option>
                    <option value="E'lon">E'lon</option>
                    <option value="Bizning yutuqlarimiz">Yutuqlarimiz</option>
                    <option value="Maqolalar">Maqolalar</option>
                    <option value="Maqolalar">Maqolalar</option>
                    <option value="Barchasi">Barchasi</option>
                  </select>

                  <label className="label-img">
                    <div className="label-box">
                      <p className="label-text">Rasim tanlang</p>
                    </div>

                    <input
                      className="input-file"
                      type="file"
                      name="photos"
                      placeholder="Imge"
                      multiple={true}
                    />

                  </label>
                </div>

                <input
                  className="input-heading"
                  type="text"
                  name="name"
                  placeholder="Sarlovha yozing"
                  required
                  defaultValue={found?.name}
                />

                <input
                  className="input-heading"
                  type="text"
                  name="title"
                  placeholder="title yozing"
                  required
                  defaultValue={found?.title}
                />

                <textarea
                  cols="50"
                  rows="10"
                  className="input-info"
                  type="text"
                  name="disc"
                  placeholder="Ma'lumot yozing"
                  required
                  defaultValue={found?.desc}
                />


                <input
                  className="input-heading"
                  type="text"
                  name="video_one"
                  placeholder="video link"
                  defaultValue={found?.video_one || ""}
                />

                <input
                  className="input-heading"
                  type="text"
                  name="video_two"
                  placeholder="video link"
                  defaultValue={found?.video_two || ''}
                />

                <input
                  className="input-heading"
                  type="text"
                  name="video_three"
                  placeholder="video link"
                  defaultValue={found?.video_three || ''}
                  />

                <input
                  className="input-heading"
                  type="text"
                  name="add_link_title"
                  placeholder="Link title"
                  defaultValue={found?.add_link_title || ''}
                  />

                <input
                  className="input-heading"
                  type="text"
                  name="add_link_url"
                  placeholder="Link"
                  defaultValue={found?.add_link_url || ''}
                />

                <input className="input-heading" type="text" name="who" placeholder="Kimdan tomondan yozildi" required defaultValue={found?.created_by} />


                <div className="status-box">
                  <span>status</span>
                  <input
                    className=""
                    type="checkbox"
                    name="status"
                    defaultChecked={found?.status}
                  />
                </div>

                <button
                  className="btn btn--position"
                  type="submit"
                >
                  O`zgartirish
                </button>
              </form>
            </div>
          </div>

          <div className={show ? "modal" : "modal--close"}>
            <div className='modal__item'>
              <h3>Title</h3>
              <p>{found?.title}</p>
              <h3>Mant</h3>
              <p>{found?.desc}</p>

              <button className='btn' onClick={() => setShow(false)}>
                Qaytish
              </button>
            </div>
          </div>

        </section>
      </main>
    </>
  );
}

export default Home;
