import { useState, useEffect } from "react";
import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";
import { useAlert } from 'react-alert';
import useToken from "../../Hooks/useToken";
import axios from "axios";

import deleteBtn from "../../assets/images/delete.svg";
import editBtn from "../../assets/images/edit.svg";



function Gallery({ setLang, lang }) {
    const alert = useAlert();
    const [data, setData] = useState()
    const [token, setToken] = useToken()
    const [id, setId] = useState()
    const [edit, setEdit] = useState(false)
    const [found, setFound] = useState()
    const [modal, setModal] = useState(false)
    const [search, setSearch] = useState('')

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/photo/' + search)
            .then(res => res.json())
            .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
            .catch((e) => console.log(e))
    }, [lang, search])


    const checkboxChange = (e) => {
        const id = JSON.parse(e.target.dataset.id);
        const status = e.target.checked

        fetch(process.env.REACT_APP_API_URL + '/editPhoto/' + lang, {
            method: "PUT",
            body: JSON.stringify({
                photo_id: id,
                photo_status: status,

            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }

    const HandleDelete = (e) => {
        const id = JSON.parse(e.target.dataset.id);

        fetch(process.env.REACT_APP_API_URL + '/photo/' + lang, {
            method: "Delete",
            body: JSON.stringify({
                photo_id: id
            }),
            headers: { token: token, "Content-Type": "application/json", },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }

    return (
        <>
            <Header />
            <main>
                <SearchInput setLang={setLang} input={true} setSearch={setSearch} lang={lang} />
                <section className="gallery">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nomi</th>
                                    <th>turi</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.photo_title}</td>
                                            <td>{e.photo_category}</td>
                                            <td>
                                                <div className="activity_checkbox_wrapper">
                                                    <label className="checkbox-container activity_checkbox-container">
                                                        <input
                                                            defaultChecked={e.photo_status}
                                                            className="activity_input"
                                                            type="checkbox"
                                                            data-id={e.photo_id}
                                                            onChange={checkboxChange}
                                                        />
                                                        <span className="checkmark activity_checkmark">
                                                            <div></div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <button>
                                                    <img
                                                        src={editBtn}
                                                        alt="edit"
                                                        onClick={() => {
                                                            setId(e.photo_id);
                                                            setEdit(!edit)
                                                            setFound({
                                                                title: e.photo_title,
                                                                status: e.photo_status,
                                                                type: e.photo_category,
                                                            })
                                                        }}
                                                    />
                                                </button>
                                                <button>
                                                    <img
                                                        src={deleteBtn}
                                                        alt="deleteBtn"
                                                        data-id={e.photo_id}
                                                        onClick={HandleDelete}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="add__btn-box">
                        <button className="add__btn" onClick={() => setModal(true)}>Qo`shish</button>
                    </div>

                    <div className={modal ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={
                                (evt) => {
                                    evt.preventDefault();
                                    const formData = new FormData();
                                    const { photo, type, title, status } = evt.target.elements

                                    formData.append("photo", photo.files[0]);
                                    formData.append("photo_title", title.value);
                                    formData.append("photo_category", type.value);
                                    formData.append("photo_status", status.checked);

                                    axios.post(process.env.REACT_APP_API_URL + "/photo/" + lang, formData, {
                                        headers: {
                                            'Content-Type': 'form-data',
                                            'Accept': 'application/json',
                                            "type": "formData",
                                            "Access-Control-Allow-Origin": "*",
                                            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                                            token: token
                                        }
                                    })
                                        .then((data) => {
                                            if (data) {
                                                if (data.data.status === 200) {
                                                    alert.success(data.data.message);
                                                }
                                                if (data.data.status === 401) {
                                                    setToken(false)
                                                }
                                                else {
                                                    alert.success(data.data.message);
                                                    console.log(data);
                                                }
                                            }
                                        });

                                    setModal(false)
                                }
                            }>
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Imge"
                                            required
                                        />
                                    </label>
                                </div>

                                <select className="select" name="type" required>
                                    <option value="0" disabled>Turini tanlang</option>
                                    <option value="Darslar va sinf xonalar">Darslar va sinf xonalar</option>
                                    <option value="Bayram va tadbirlar">Bayram va tadbirlar</option>
                                    <option value="Turli musobaqa va oʻyinlar">Turli musobaqa va oʻyinlar</option>
                                    <option value="Hamma rasmlar">Hamma rasmlar</option>
                                </select>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="title"
                                    placeholder="Photo title"
                                    required
                                />

                                <input
                                    className=""
                                    type="checkbox"
                                    name="status"
                                />

                                <button className="btn">Qo`shish</button>
                            </form>
                        </div>
                    </div>


                    <div className={edit ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={
                                (evt) => {
                                    evt.preventDefault();
                                    const formData = new FormData();
                                    const { photo, type, title, status } = evt.target.elements

                                    formData.append("photo_id", id);
                                    formData.append("photo", photo.files[0]);
                                    formData.append("photo_title", title.value);
                                    formData.append("photo_category", type.value);
                                    formData.append("photo_status", status.checked);

                                    axios.put(process.env.REACT_APP_API_URL + "/photo/" + lang, formData, {
                                        headers: {
                                            'Content-Type': 'form-data',
                                            'Accept': 'application/json',
                                            "type": "formData",
                                            "Access-Control-Allow-Origin": "*",
                                            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                                            token: token
                                        }
                                    })
                                        .then((data) => {
                                            if (data) {
                                                if (data.data.status === 200) {
                                                    alert.success(data.data.message);
                            
                                                }
                                                if (data.data.status === 401) {
                                                    setToken(false)
                                                }
                                                else {
                                                    alert.success(data.data.message);
                                                    console.log(data);
                                                }
                                            }
                                        });

                                    setEdit(false)
                                }
                            }>
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Imge"
                                        />
                                    </label>
                                </div>

                                <select className="select" name="type" required defaultValue={found?.type}>
                                    <option value="0" disabled>Turini tanlang</option>
                                    <option value="Darslar va sinf xonalar">Darslar va sinf xonalar</option>
                                    <option value="Bayram va tadbirlar">Bayram va tadbirlar</option>
                                    <option value="Turli musobaqa va oʻyinlar">Turli musobaqa va oʻyinlar</option>
                                    <option value="Hamma rasmlar">Hamma rasmlar</option>
                                </select>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="title"
                                    placeholder="type title"
                                    defaultValue={found?.title}
                                    required
                                />

                                <input
                                    className=""
                                    type="checkbox"
                                    name="status"
                                    defaultChecked={found?.status}
                                />

                                <button className="btn">O`zgartirish</button>
                            </form>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}

export default Gallery