import './social.scss'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useToken from '../../Hooks/useToken';


import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";

function Social({ setLang, lang }) {
    const navigate = useNavigate();
    const alert = useAlert();
    const [data, setData] = useState()
    const [modal, setModal] = useState()
    const [token, setToken] = useToken()    


    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/general')
            .then(res => res.json())
            .then(data => lang === 'uz' ? setData(data.data?.uz[0]) : setData(data.data?.ru[0]))
            .catch((e) => console.log(e))
    }, [lang])

    console.log(data);

    const HandleEdit = (e) => {
        e.preventDefault();
        const { phone, email, insta, telegram, address, locition, facebook, youtube, work } = e.target.elements

        fetch(process.env.REACT_APP_API_URL + '/general/' + lang, {
            method: "PUT",
            body: JSON.stringify({
                phone: phone.value.trim(),
                email: email.value.trim(),
                address: address.value.trim(),
                locition: locition.value.trim(),
                work_time: work.value.trim(),
                telegram_link: telegram.value.trim(),
                facebook_link: facebook.value.trim(),
                instagram_link: insta.value.trim(),
                youtube_link: youtube.value.trim()
            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }

    return (
        <>
            <Header />
            <main>
                <SearchInput setLang={setLang} input={false} lang={lang} />
                <section className="social">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nomi</th>
                                    <th>Link</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Telefon</td>
                                    <td>{data?.phone}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{data?.email}</td>
                                </tr>
                                <tr>
                                    <td>address</td>
                                    <td>{data?.address}</td>
                                </tr>
                                <tr>
                                    <td>locition</td>
                                    <td>{data?.locition}</td>
                                </tr>
                                <tr>
                                    <td>work_time</td>
                                    <td>{data?.work_time}</td>
                                </tr>
                                <tr>
                                    <td>telegram_link</td>
                                    <td>{data?.telegram_link}</td>
                                </tr>
                                <tr>
                                    <td>facebook_link</td>
                                    <td>{data?.facebook_link}</td>
                                </tr>
                                <tr>
                                    <td>instagram_link</td>
                                    <td>{data?.instagram_link}</td>
                                </tr>
                                <tr>
                                    <td>youtube_link</td>
                                    <td>{data?.youtube_link}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="add__btn-box">
                        <button className="add__btn" onClick={() => setModal(true)}>O`zgartirish</button>
                    </div>

                    <div className={modal ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="" onSubmit={HandleEdit}>

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="phone"
                                    placeholder="Phone number"
                                    defaultValue={data?.phone}
                                    required
                                />
                                <input
                                    className="input-heading"
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    defaultValue={data?.email}
                                    required
                                />

                                <input
                                    className="input-heading"
                                    type="text"
                                    name="address"
                                    placeholder="Address name"
                                    required
                                    defaultValue={data?.address}
                                />
                                <input
                                    className="input-heading"
                                    type="text"
                                    name="locition"
                                    placeholder="Locition link"
                                    required
                                    defaultValue={data?.locition}
                                />
                                <input
                                    className="input-heading"
                                    type="text"
                                    name="insta"
                                    placeholder="Instagram link"
                                    required
                                    defaultValue={data?.instagram_link}
                                />
                                <input
                                    className="input-heading"
                                    type="text"
                                    name="telegram"
                                    placeholder="Telegram link"
                                    required
                                    defaultValue={data?.telegram_link}
                                />
                                <input
                                    className="input-heading"
                                    type="text"
                                    name="facebook"
                                    placeholder="Facebook link"
                                    required
                                    defaultValue={data?.facebook_link}
                                />
                                <input
                                    className="input-heading"
                                    type="text"
                                    name="youtube"
                                    placeholder="Youtube link"
                                    required
                                    defaultValue={data?.youtube_link}
                                />
                                <input
                                    className="input-heading"
                                    type="text"
                                    name="work"
                                    placeholder="Work time"
                                    required
                                    defaultValue={data?.work_time}
                                />
                                <button className="btn" onClick={() =>
                                    setTimeout(() => {
                                        navigate('/social');
                                        setModal(false)
                                    }, 2000)
                                }>O`zgartirish</button>
                            </form>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )
}

export default Social