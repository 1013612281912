import './activity.scss'
import { useState, useEffect } from "react";
import useToken from '../../Hooks/useToken';
import { useAlert } from 'react-alert';
import axios from 'axios'

import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";

import deleteBtn from "../../assets/images/delete.svg";
import editBtn from "../../assets/images/edit.svg";


function Activity({ setLang, lang }) {
    const alert = useAlert();
    const [data, setData] = useState()
    const [token, setToken] = useToken()
    const [id, setId] = useState()
    const [modal, setModal] = useState(false)
    const [edit, setEdit] = useState(false)
    const [show, setShow] = useState(false)
    const [found, setFound] = useState()

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/activity/')
            .then(res => res.json())
            .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
            .catch((e) => console.log(e))
    }, [lang])

    const HandleDelete = (e) => {
        const id = JSON.parse(e.target.dataset.id);

        fetch(process.env.REACT_APP_API_URL + '/activity/' + lang, {
            method: "Delete",
            body: JSON.stringify({
                activity_id: id
            }),
            headers: { token: token, "Content-Type": "application/json", },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });
    }


    const HandleAdd = (evt) => {
        evt.preventDefault();
        const formData = new FormData();

        let { status, photo, title } = evt.target.elements

        for (let i = 0; i < photo.files.length; i++) {
            formData.append("photos", photo.files[i]);
        }
        formData.append("activity_title", title.value);
        formData.append("activity_status", status.checked);

        axios.post(process.env.REACT_APP_API_URL + "/activity/" + lang, formData, {
            headers: {
                'Content-Type': 'form-data',
                'Accept': 'application/json',
                "type": "formData",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                token: token
            }
        })
            .then((data) => {
                if (data) {
                    if (data.data.status === 200) {
                        alert.success(data.data.message);

                    }
                    if (data.data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.data.message);
                        console.log(data);
                    }
                }
            });

        setModal(false)
    }

    const HandleEdit = (evt) => {
        evt.preventDefault();
        const formData = new FormData(document.querySelector('.form-data'));

        let { status, photo, title } = evt.target.elements

        for (let i = 0; i < photo.files.length; i++) {
            formData.append("photos", photo.files[i]);
        }
        formData.append("activity_id", id);
        formData.append("activity_title", title.value);
        formData.append("activity_status", status.checked);

        axios.put(process.env.REACT_APP_API_URL + "/activity/" + lang, formData, {
            headers: {
                'Content-Type': 'form-data',
                'Accept': 'application/json',
                "type": "formData",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
                token: token
            }
        })
            .then((data) => {
                if (data) {
                    if (data.data.status === 200) {
                        alert.success(data.data.message);

                    }
                    if (data.data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.data.message);
                        console.log(data);
                    }
                }
            });

        setEdit(false)
    }

    const checkboxChange = (e) => {
        const id = JSON.parse(e.target.dataset.id);
        const status = e.target.checked

        fetch(process.env.REACT_APP_API_URL + '/editActivity/' + lang, {
            method: "PUT",
            body: JSON.stringify({
                activity_id: id,
                activity_status: status,

            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            }
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 200) {
                    alert.success(data.message);
                }
                if (data.status === 401) {
                    setToken(false)
                }
                else {
                    alert.success(data.message);
                    console.log(data);
                }
            });
    }

    return (
        <>
            <Header />
            <main className="">
                <SearchInput setLang={setLang} input={false} lang={lang} />
                <section className="activity">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nomi</th>
                                    <th>Holati</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((e, i) => (
                                        <tr key={i} className="table_tr">
                                            <td>
                                                <p>{e.activity_title.split(' ').slice(0, 5).join(' ') + '...'}</p>
                                            </td>
                                            <td>
                                                <div className="customers_checkbox_wrapper">
                                                    <label className="checkbox-container customers_checkbox-container">
                                                        <input
                                                            defaultChecked={e.activity_status}
                                                            required
                                                            className="customer_input"
                                                            type="checkbox"
                                                            data-id={e.activity_id}
                                                            onChange={checkboxChange}

                                                        />
                                                        <span className="checkmark customers_checkmark">
                                                            <div></div>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>
                                                <button className='show' onClick={() => {
                                                    setFound({
                                                        title: e.activity_title
                                                    })
                                                    setShow(true)
                                                }}>
                                                    ko'proq
                                                </button>
                                            </td>
                                            <td>
                                                <button>
                                                    <img
                                                        src={editBtn}
                                                        alt="edit"
                                                        onClick={() => {
                                                            setId(e.activity_id);
                                                            setEdit(true)
                                                            setFound({
                                                                title: e.activity_title,
                                                                status: e.activity_status
                                                            })
                                                        }}
                                                    />
                                                </button>
                                                <button>
                                                    <img
                                                        src={deleteBtn}
                                                        alt="deleteBtn"
                                                        data-id={e.activity_id}
                                                        onClick={HandleDelete}
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>


                    <div className="add__btn-box">
                        <button className="add__btn" onClick={() => setModal(true)}>Qo`shish</button>
                    </div>

                    <div className={modal ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="form-data" onSubmit={HandleAdd}>
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Image"
                                            multiple={true}
                                            required
                                        />
                                    </label>
                                </div>

                                <textarea
                                    cols="50"
                                    rows="10"
                                    className="input-info"
                                    type="text"
                                    name="title"
                                    placeholder="Info"
                                    required
                                />

                                <input className='input' name='status' type="checkbox" required />

                                <button className="btn">Qo`shish</button>
                            </form>
                        </div>
                    </div>

                    <div className={edit ? "modal" : "modal--close"}>
                        <div className="modal__item">
                            <form className="form-data" onSubmit={HandleEdit}>
                                <div className="">
                                    <label className="label-img">
                                        <div className="label-box">
                                            <p className="label-text">Rasim tanlang</p>
                                        </div>
                                        <input
                                            className="input-file"
                                            type="file"
                                            name="photo"
                                            placeholder="Image"
                                            multiple={true} />
                                    </label>
                                </div>

                                <textarea
                                    cols="50"
                                    rows="10"
                                    className="input-info"
                                    type="text"
                                    name="title"
                                    placeholder="Info"
                                    defaultValue={found?.title || ''}
                                />

                                <input className='input' name='status' type="checkbox" defaultChecked={found?.status} />

                                <button className="btn">O'zgartirish</button>
                            </form>
                        </div>
                    </div>

                    <div className={show ? "modal" : "modal--close"}>
                        <div className='modal__item'>
                            <p>{found?.title}</p>
                            <button className='btn' onClick={() => setShow(false)}>
                                Qaytish
                            </button>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}

export default Activity