import "./addNews.scss";
import React from "react";
import Header from "../header/header";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import useToken from '../../Hooks/useToken';
import { useAlert } from "react-alert";


export default function MyEditor({ lang, setLang }) {
  const alert = useAlert();
  const [token, setToken] = useToken()
  const navigate = useNavigate()


  const HandleSubmit = (evt) => {
    evt.preventDefault();
    const { photos, type, name, title, disc, who, video_one, video_two, video_three, add_link_title, add_link_url, status } = evt.target.elements

    const formData = new FormData();

    for (let i = 0; i < photos.files.length; i++) {
      formData.append("photos", photos.files[i]);
    }
    formData.append("post_name", name.value);
    formData.append("post_title", title.value);
    formData.append("post_discription", disc.value);
    formData.append("post_type", type.value);
    formData.append("post_created_by", who.value);
    formData.append("post_video_one", video_one.value);
    formData.append("post_video_two", video_two.value);
    formData.append("post_video_three", video_three.value);
    formData.append("add_link_title", add_link_title.value);
    formData.append("add_link_url", add_link_url.value);
    formData.append("post_status", status.checked);

    axios.post(process.env.REACT_APP_API_URL + "/post/" + lang, formData, {
      headers: {
        "type": "formData",
        "Content-Type": "form-data",
        "Accept": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        token: token
      }
    })
      .then((data) => {
        if (data) {
          if (data.data.status === 200) {
            alert.success(data.data.message);
            navigate(-1)
          }
          if (data.data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.data.message);
            console.log(data);
          }
        }
      });
  }

  return (
    <>
      <Header />
      <main className="main">
        <div className="container">
          <form className="l" onSubmit={HandleSubmit}>
            <div className="form__box">

              <select className="select" name="type" required>
                <option value="0" disabled>Turini tanlang</option>
                <option value="Yangliklar">Yangliklar</option>
                <option value="E'lon">E'lon</option>
                <option value="Yutuqlarimiz">Yutuqlarimiz</option>
                <option value="Maqolalar">Maqolalar</option>
                <option value="Barchasi">Barchasi</option>
              </select>

              <label className="label-img">
                <div className="label-box">
                  <p className="label-text">Rasim tanlang</p>
                </div>

                <input
                  className="input-file"
                  type="file"
                  name="photos"
                  placeholder="Imge"
                  multiple={true}
                  required
                />

              </label>
            </div>

            <input
              className="input-heading"
              type="text"
              name="name"
              placeholder="Sarlovha yozing"
              required
            />

            <input
              className="input-heading"
              type="text"
              name="title"
              placeholder="title yozing"
              required
            />

            <textarea
              cols="50"
              rows="10"
              className="input-info"
              type="text"
              name="disc"
              placeholder="Ma'lumot yozing"
              required
            />

            <input
              className="input-heading"
              type="text"
              name="video_one"
              placeholder="video link"
            />

            <input
              className="input-heading"
              type="text"
              name="video_two"
              placeholder="video link"
            />

            <input
              className="input-heading"
              type="text"
              name="video_three"
              placeholder="video link"
            />

            <input
              className="input-heading"
              type="text"
              name="add_link_title"
              placeholder="Qo'shimcha link izohi"
            />           

            <input
              className="input-heading"
              type="text"
              name="add_link_url"
              placeholder="Qo'shimcha link url"
            />           

            <input className="input-heading" type="text" name="who" placeholder="Kimdan tomondan yozildi" required />


            <div className="status-box">
              <span>status</span>
              <input
                className=""
                type="checkbox"
                name="status"
              />
            </div>

            <button
              className="btn btn--position"
              type="submit"
            >
              Qo`shish
            </button>

          </form>
        </div>
      </main>
    </>
  );
}
