import "./users.scss";
import { useState, useEffect } from "react";
import Header from "../header/header";
import SearchInput from "../SearchInput/SearchInput";
import { useAlert } from 'react-alert';
import useToken from '../../Hooks/useToken';
import axios from 'axios'

import deleteBtn from "../../assets/images/delete.svg";
import editBtn from "../../assets/images/edit.svg";

function Users({ lang, setLang }) {
  const alert = useAlert();
  const [token, setToken] = useToken()
  const [data, setData] = useState();
  const [modal, setModal] = useState(false)
  const [found, setFound] = useState()
  const [edit, setEdit] = useState(false)
  const [id, setId] = useState();
  const [search, setSearch] = useState('')



  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + '/employees/' + search)
      .then(res => res.json())
      .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
      .catch((e) => console.log(e))
  }, [lang, search])


  const HandleAdd = (evt) => {
    evt.preventDefault();
    const formData = new FormData();

    let { img, name, role, winning, degree, info, telegram, instagram, facebook, email, status } = evt.target.elements

    formData.append("employee_name", name.value);
    formData.append("photo", img.files[0]);
    formData.append("employee_role", role.value);
    formData.append("employee_winning", winning.value);
    formData.append("employee_full_info", info.value);
    formData.append("employee_academic_degree", degree.value);
    formData.append("employee_email", email.value,);
    formData.append("employee_telegram_link", telegram.value);
    formData.append("employee_facebook_link", facebook.value);
    formData.append("employee_instagram_link", instagram.value,);
    formData.append("employee_status", status.checked);

    console.log(degree.value);


    axios.post(process.env.REACT_APP_API_URL + "/employees/" + lang, formData, {
      headers: {
        'Content-Type': 'form-data',
        'Accept': 'application/json',
        "type": "formData",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        token: token
      }
    })
      .then((data) => {
        if (data) {
          if (data.data.status === 200) {
            alert.success(data.data.message);

          }
          if (data.data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.data.message);
            console.log(data);
          }
        }
      });

    setModal(false)
  }


  const HandleEdit = (evt) => {
    evt.preventDefault();
    const formData = new FormData();

    let { img, name, role, winning, degree, info, telegram, instagram, facebook, email, status } = evt.target.elements

    formData.append("employee_id", id);
    formData.append("employee_name", name.value);
    formData.append("photo", img.files[0]);
    formData.append("employee_role", role.value);
    formData.append("employee_winning", winning.value);
    formData.append("employee_full_info", info.value);
    formData.append("employee_academic_degree", degree.value);
    formData.append("employee_telegram_link", telegram.value);
    formData.append("employee_facebook_link", facebook.value);
    formData.append("employee_instagram_link", instagram.value,);
    formData.append("employee_email", email.value,);
    formData.append("employee_status", status.checked);

    axios.put(process.env.REACT_APP_API_URL + "/employees/" + lang, formData, {
      headers: {
        'Content-Type': 'form-data',
        'Accept': 'application/json',
        "type": "formData",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        token: token
      }
    })
      .then((data) => {
        if (data) {
          if (data.data.status === 200) {
            alert.success(data.data.message);

          }
          if (data.data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.data.message);
            console.log(data);
          }
        }
      });

    setEdit(false)
  }

  const HandleDelete = (e) => {
    const id = e.target.dataset.id;

    fetch(process.env.REACT_APP_API_URL + '/employees/' + lang, {
      method: "Delete",
      body: JSON.stringify({
        employee_id: id
      }),
      headers: { token: token, "Content-Type": "application/json", },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          if (data.status === 200) {
            alert.success(data.message);
          }
          if (data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.message);
            console.log(data);
          }
        }
      });
  }

  const checkboxChange = (e) => {
    const id = JSON.parse(e.target.dataset.id);
    const status = e.target.checked

    fetch(process.env.REACT_APP_API_URL + '/editEmployees/' + lang, {
      method: "PUT",
      body: JSON.stringify({
        employee_id: id,
        employee_status: status,

      }),
      headers: {
        "Content-Type": "application/json",
        token: token
      }
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          if (data.status === 200) {
            alert.success(data.message);
          }
          if (data.status === 401) {
            setToken(false)
          }
          else {
            alert.success(data.message);
            console.log(data);
          }
        }
      });
  }

  return (
    <>
      <Header />
      <main>
        <SearchInput lang={lang} input={true} setSearch={setSearch} setLang={setLang} />
        <section className="">
          <div className="container">
            <table>
              <thead>
                <tr>
                  <th>Nomi</th>
                  <th>role</th>
                  <th>status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  data && data.map((e, i) => (
                    <tr key={i}>
                      <td>{e.employee_name}</td>
                      <td>{e.employee_role}</td>
                      <td>
                        <div className="customers_checkbox_wrapper">
                          <label className="checkbox-container customers_checkbox-container">
                            <input
                              defaultChecked={e.employee_status}
                              required
                              className="customer_input"
                              type="checkbox"
                              data-id={e.employee_id}
                              onChange={checkboxChange}

                            />
                            <span className="checkmark customers_checkmark">
                              <div></div>
                            </span>
                          </label>
                        </div>
                      </td>
                      <td>
                        <button>
                          <img
                            src={editBtn}
                            alt="edit"
                            onClick={() => {
                              setId(e.employee_id);
                              setEdit(!edit)
                              setFound({
                                name: e.employee_name,
                                role: e.employee_role,
                                degree: e.employee_academic_degree,
                                info: e.employee_full_info,
                                winning: e.employee_winning,
                                telegram: e.employee_telegram_link,
                                facebook: e.employee_facebook_link,
                                instagram: e.employee_instagram_link,
                                email: e.employee_email,
                                status: e.employee_status
                              })
                            }}
                          />
                        </button>
                        <button>
                          <img
                            src={deleteBtn}
                            alt="deleteBtn"
                            data-id={e.employee_id}
                            onClick={HandleDelete}
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>

          <div className="add__btn-box">
            <button className="add__btn" onClick={() => setModal(true)}>Qo`shish</button>
          </div>


          <div className={modal ? "modal" : "modal--close"}>
            <div className="modal__item">
              <form className="" onSubmit={HandleAdd}>

                <div className="">
                  <label className="label-img">
                    <div className="label-box">
                      <p className="label-text">Rasim tanlang</p>
                    </div>
                    <input
                      className="input-file"
                      type="file"
                      name="img"
                      placeholder="Imge"
                      required
                    />
                  </label>
                </div>

                <input
                  className="input-heading"
                  type="text"
                  name="name"
                  placeholder="Hodim ismi"
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="role"
                  placeholder="Hodim lavozimi"
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="winning"
                  placeholder="Hodim yutuqlar"
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="degree"
                  placeholder="Hodim ilmiy darajasi"
                  required
                />

                <textarea
                  cols="50"
                  rows="10"
                  className="input-info"
                  type="text"
                  name="info"
                  placeholder="Info"
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="telegram"
                  placeholder="Telegram link"
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="instagram"
                  placeholder="Instagram link"
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="facebook"
                  placeholder="Facebook link"
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="email"
                  placeholder="Email link"
                  required
                />

                <label>
                  status
                  <input
                    className="input-checkbox"
                    type="checkbox"
                    name="status"
                  />
                </label>

                <button className="btn">Qo`shish</button>
              </form>
            </div>
          </div>

          <div className={edit ? "modal" : "modal--close"}>
            <div className="modal__item">
              <form className="" onSubmit={HandleEdit}>

                <div className="">
                  <label className="label-img">
                    <div className="label-box">
                      <p className="label-text">Rasim tanlang</p>
                    </div>
                    <input
                      className="input-file"
                      type="file"
                      name="img"
                      placeholder="Imge" />
                  </label>
                </div>

                <input
                  className="input-heading"
                  type="text"
                  name="name"
                  placeholder="Employeer name"
                  defaultValue={found?.name || ''}
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="role"
                  placeholder="Employeer role"
                  defaultValue={found?.role || ''}
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="winning"
                  placeholder="Employeer winning"
                  defaultValue={found?.winning || ''}
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="degree"
                  placeholder="Employeer academic degree"
                  defaultValue={found?.degree || ''}
                  required
                />

                <textarea
                  cols="50"
                  rows="10"
                  className="input-info"
                  type="text"
                  name="info"
                  placeholder="Info"
                  defaultValue={found?.info || ''}
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="telegram"
                  placeholder="Telegram link"
                  defaultValue={found?.telegram || ''}
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="instagram"
                  placeholder="Instagram link"
                  defaultValue={found?.instagram || ''}
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="facebook"
                  placeholder="Facebook link"
                  defaultValue={found?.facebook || ''}
                  required
                />

                <input
                  className="input-heading"
                  type="text"
                  name="email"
                  placeholder="Email link"
                  defaultValue={found?.email || ''}
                  required

                />

                <label>
                  status
                  <input
                    className="input-checkbox"
                    type="checkbox"
                    name="status"
                    defaultChecked={found?.status}
                  />
                </label>

                <button className="btn">O`zgartirish</button>
              </form>
            </div>
          </div>

        </section>
      </main>
    </>
  );
}

export default Users;
