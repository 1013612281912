import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import useToken from '../../Hooks/useToken';

// import logo from "../../assets/images/logo.jpg";

function Header() {
  const [, setToken] = useToken();

  return (
    <header>
      <div className="nav-bar_wrapper">
        <div className="nav-bar_wrapper_inner">
          <div className="logo_wrapper">
            <Link to="/" className="title">
              ZIYO-ZUKKO
            </Link>
          </div>
          <nav>
            <ul className="nav_list">
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/"
                >
                  Yangliklar
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/users"
                >
                  Ishchilar
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/investor"
                >
                  Hamkorlar
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/video"
                >
                  Video
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/activity"
                >
                  Faoliyat
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/appeals"
                >
                  Murojaatlar
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/certificate"
                >
                  Sertifikat
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/management"
                >
                  Boshqaruvchilar
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/social"
                >
                  Ijtimoiy tarmoqlar
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/about"
                >
                  Haqida
                </NavLink>
              </li>
              <li className="nav_list_item">
                <NavLink
                  className="nav_link"
                  to="/gallery"
                >
                  Galereya
                </NavLink>
              </li>
              <li className="nav_list_item">
                <a
                  className="nav_link"
                  href="https://ziyozukko.uz/">
                    Saytga o`tish
                </a>
              </li>
              <li className="nav_list_item">
                <button
                  className='logout-modal__button'
                  onClick={() => {
                    setToken(false);
                  }}>
                  Log out
                </button>
              </li>
            </ul>
          </nav>

        </div>
      </div>
    </header>
  );
}

export default Header;