import './about.scss'
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import useToken from '../../Hooks/useToken';


import Header from "../header/header"
import SearchInput from '../SearchInput/SearchInput'

import edit from "../../assets/images/edit.svg";



function About({ setLang, lang }) {
    const navigate = useNavigate();
    const alert = useAlert();
    const [data, setData] = useState()
    const [id, setId] = useState()
    const [token, setToken] = useToken()
    const [modal, setModal] = useState(false)
    const [found, setFound] = useState()

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + '/about', {
            method: "GET"
        })
            .then(res => res.json())
            .then(data => lang === 'uz' ? setData(data.data.uz) : setData(data.data.ru))
            .catch((e) => console.log(e))
    }, [lang])



    const HandleUpdate = (evt) => {
        evt.preventDefault();
        const { title, count } = evt.target.elements

        fetch(process.env.REACT_APP_API_URL + "/about/" + lang, {
            method: "PUT",
            body: JSON.stringify({
                about_id: id,
                about_title: title.value.trim(),
                about_count: count.value.trim()
            }),
            headers: {
                "Content-Type": "application/json",
                token: token
            },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data) {
                    if (data.status === 200) {
                        alert.success(data.message);
                        console.log(data);

                    }
                    if (data.status === 401) {
                        setToken(false)
                    }
                    else {
                        alert.success(data.message);
                        console.log(data);
                    }
                }
            });

    }

    return (
        <>
            <Header />

            <main>
                <SearchInput setLang={setLang} input={false} lang={lang} />
                <section className="about">
                    <div className="container">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nomi</th>
                                    <th>Soni</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.map((e, i) => (
                                        <tr key={i}>
                                            <td>{e.about_title}</td>
                                            <td>{e.about_count}</td>
                                            <td>
                                                <button>
                                                    <img
                                                        src={edit}
                                                        alt="edit"
                                                        onClick={
                                                            () => {
                                                                setId(e.about_id)
                                                                setFound({
                                                                    title: e.about_title,
                                                                    count: e.about_count
                                                                })
                                                                setModal(true)
                                                            }
                                                        }
                                                    />
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </section>


                <div className={modal ? "modal" : "modal--close"}>
                    <div className="modal__item">
                        <form className="" onSubmit={HandleUpdate}>

                            <input
                                className="input-heading"
                                type="text"
                                name="title"
                                placeholder="About title"
                                required
                                defaultValue={found?.title || ""}
                            />

                            <input
                                className="input-heading"
                                type="text"
                                name="count"
                                placeholder="About count"
                                required
                                defaultValue={found?.count || ""}
                            />

                            <button className="btn" onClick={() =>
                                setTimeout(() => {
                                    navigate('/about');
                                    setModal(false)
                                }, 1200)
                            }>O`zgartirish</button>
                        </form>
                    </div>
                </div>
            </main>
        </>
    )
}

export default About